// 创建文件en.js
const en = {
    language: {
        zh: '中文',
        en: 'English',
        ja:'Japanese'
    },
    // 头部导航
    globalization:'English',
    modelCar:'Model Cars',
    saleChannel:'Sales Channels',
    download:'Download',
    aboutUs:'About Us',
    toyotaSeries:'Toyota Series',
    lexusSeries:'Lexus Series',
    AllSeries:'Comprehensive Series',
    // 首页
    indexToyota:'Toyota Department',
    indexLexus:'Lexus Department',
    indexAll:'Comprehensive Department',
    showLicensor:'Licensee Display',
    indexMore:'Explore More',
    indexPrice:'Market Reference Price',
    indexModel:'Car Model',
    common:'Common',
    kind:'Payment',
    // 详情页面
    Reference:'Reference',
    wan:'myriads',
    // 技术参数
    lwh:'Length Width Height',
    curb:'Curb Weight',
    wheelbase:'Wheelbase',
    power:'Power',
    displacement:'Displacement',
    torque:'Torque',
    tank:'Tank',
    size:'Tire Size',
    technical:'Technical Parameter',
    configuration:'Main Configuration',
    // 底部导航
    index:'Home page',
    garagePublicAccount:'Garage public account',
    garageNetworkTechnology:'Garage Network Technology',
    garageVibrato:'Garage Vibrato',
    contactUs:'Contact us',
    copyright:'Copyright Garage Network Technology Co., Ltd.',
    // 关于我们
    firstBusiness:'The first business segment-Import Plate',
    firstContent:'Garage Network Technology Co. , Ltd. . International procurement business involved in more than 20 countries, more than 50 suppliers. And in Dubai, Canada, the United States, Germany has a branch. The annual purchase volume is more than 30,000 units, and the purchase amount is about 2 billion US dollars. Purchase models involving Nissan, Toyota, Land Rover, Mercedes-benz, BMW, Ford, Mitsubishi, Porsche, Maserati and more than 10 brands more than 40 models. For seven consecutive years, domestic parallel imported cars ranked first in the international procurement volume.',
    secondBussiness:'The second business sector - Export Sector',
    secondContentOne:'Garage Network Technology Co. , Ltd. has a total of eight pilot enterprises in Tianjin, Ningbo, Zhangjiagang, Yueyang, Jiangyin, Nansha, Chengdu and other seaports and inland ports. And in the port customs declaration, warehousing, transportation, wholesale distribution of business.',
    secondContentTwo:'Garage company for the national dealers to provide delivery to the place, to the shop of the distribution services, and to meet the needs of merchants to provide inventory finance, logistics finance and other financial services.',
    secondContentThree:'The supplier system covers 68 countries, the number of suppliers exceeds 300, and the number of global distributors exceeds 1,200.',
    secondContentFour:'The export volume in 2018 was 17,000 units, the export volume in 2019 was 16,700 units, and the export of 3,695 vehicles was completed in the first half of 2020.',
    secondContentFive:'With the growing demand for new energy vehicles from global customers, Sino-Ocean Trading has also been committed to providing customers with the best quality services in the new energy vehicle business since 2020. So far, the export models of new energy vehicles are Volkswagen ID series, Nissan pure electric EV, Changan ESTAR and other models, which are sold to Africa, Nigeria, Algeria, Morocco, Ghana, Seychelles and other countries, as well as the Middle East and other regions. New energy import qualification countries: Saudi Arabia, Iran, Iraq, Kuwait, UAE, Oman, Qatar, Bahrain, Turkey, Israel, Palestine, Syria, Lebanon, Jordan, Yemen and Cyprus, Egypt, Libya, Tunisia, Algeria, Morocco, Malaysia Delray Islands, Azores.',
    threeBussiness:'The third business sector - Brand Chain Sector',
    threeContentOne:'Garage Network Technology Co. , Ltd. in the countrys 30 provinces and cities have more than 3600 cooperative terminal business, business transactions active business more than 1200. The coverage rate of prefecture-level cities and above reached 70% . Annual sales in more than 27,000 units, annual sales in more than 20 billion.',
    threeContentTwo:'In order to standardize sales and provide high-quality service to our customers, we will build brand chain stores based on the garage brand in the provincial capital cities. The parallel import of cars, customized modifications, intelligent applications and other models and accessories, brand and culture, trends and awareness into the store. Create a brand new car sales space',
    fourBussiness:'Fourth business segment - Custom Modification Segment',
    fourContentOne:'Garage Network Technology Co., Ltd.s international procurement business involves more than 20 countries and more than 50 suppliers around the world. And has branches in Dubai, Canada, the United States, and Germany. The annual purchase volume is more than 30,000 units, and the purchase amount is about 2 billion US dollars. The purchased models involve more than 40 models of more than a dozen brands including Nissan, Toyota, Land Rover, Mercedes-Benz, BMW, Ford, Mitsubishi, Porsche and Maserati. For seven consecutive years, it has ranked first in international purchases of domestic parallel imported vehicles.',
    fourContentTwo:'The imported cars are refitted in different sections, mainly for the imported cars in the first segment. Form the garages own brand shape. Domineering, Cool Luze, Lexus, etc.',
    fourContentThree:'High-end customized modification, mainly for high-end business travel, with Mercedes-Benz V series, Toyota Sienna and other MPVs as prototype vehicles for brand customization upgrades and modifications',
    fourContentFour:'For domestic car modification, we cooperate with major domestic OEMs to upgrade and modify some of their models. thereby increasing its sales. It has invested in the construction of a refit base in Yueyang. It covers a total area of 105.12 acres and has a total construction scale of more than 80,000 square meters. It has established a car modification custom factory, a motor vehicle testing and certification center, a comprehensive building, a first-class modified factory, a second-class modified factory, warehouse, activity center, exhibition center, restaurant, etc. .',
    // fiveBussiness:'Fifth Business Sector - Industrial Informatization Design and Management',
    // fiveContent:'Through the research and actual operation of parallel imports, Garage.coms product R&D team has successively launched the "parallel import car inventory system", "parallel import car ERP system", "enterprise management OA system", "enterprise operation DMS management system" , "Logistics and Transportation Management System", "After-sale Vehicle Management System". It will also launch the "Auto Broker Sales Platform", "Auto Show Sales and Parts AI Model" and so on. Through the close combination of Internet technology and parallel imported vehicles, a series of Internet + solutions for parallel imported vehicles are launched with the "Garage Network" brand as the core.',
    // 资料下载
    video:'video',
    img:'picture',
    detail:'detail',
    allDownload:'All download',
    cancel:'No',
    yes:'Yes',
    proof:'Personal Identification',
    enterPhone:'Please enter phone number',
    downloadTitle:'High-quality big picture/video, covering all kinds of high-definition car series',
    // 销售渠道
    salephone:'National Retail Business Phone',
    complaints:'Complaint Hotline',
    borker:'Gold broker',
    job:'Duration',
    saleNum:'Clinch',
    saleYear:'Years',
    saleTower:'Towers',
    chat:'WeChat',
    saleTelephone:'Nationwide merchant affiliated telephone',
    saleTitle:'National car dealership',
    carToyota:'Toyota (parallel import)',
    carLexus:'Lexus (parallel import)',
    carALL:'Comprehensive (parallel import)',
    salePro:'Area',
    saleName:'Dealer Name',
    saleAdress:'Address',
    saleContact:'Contact',
    telephone:'Telephone',
    modelAuthorize:'Authorized Models',
}
export default en;
