import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入axios
import axios from "axios";
axios.defaults.baseURL = "http://47.93.9.150:10003/";
// axios.defaults.baseURL = "http://192.168.18.25:3216/";
Vue.prototype.axios = axios;
import i18n from "./utils/vueIN";

import "video.js/dist/video-js.css";
import videojs from "video.js";
// 动画样式
import "animate.css";
// 引入element
import {
  Menu,
  MenuItem,
  Submenu,
  Icon,
  Tooltip,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tree,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Dialog,
  Button,
  Message,
  Select,
  Option,
  Image,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Icon);
Vue.use(Tooltip);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Tree);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Pagination);
Vue.use(Breadcrumb),
  Vue.use(BreadcrumbItem),
  Vue.use(Input),
  Vue.use(Dialog),
  Vue.use(Button),
  Vue.use(Select),
  Vue.use(Option),
  Vue.use(Image),
  (Vue.config.productionTip = false);

// 事件总线
import VueBus from "vue-bus";
Vue.prototype.bus = new Vue();

Vue.use(VueBus);
Vue.prototype.$message = Message;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
