import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    imgbase: "https://ck-admin-pc.oss-cn-beijing.aliyuncs.com/Ck-Index/",
    model: [],
  },
  getters: {
    getModel: (state) => state.model,
  },
  mutations: {
    setModel(state, model) {
      state.model = model;
    },
  },
  actions: {},
  modules: {},
});
