// 创建文件zh.js
const zh = {
    language: {
        zh: '中文',
        en: 'English',
        ja:'Japanese'
    },
    // 头部导航
    globalization:'中文',
    modelCar:'车型车系',
    saleChannel:'销售渠道',
    download:'资料下载',
    aboutUs:'关于我们',
    toyotaSeries:'丰田系列',
    lexusSeries:'雷克萨斯系列',
    AllSeries:'综合系列',
    // 首页
    indexToyota:'丰田系',
    indexLexus:'雷克萨斯系',
    indexAll:'综合系',
    showLicensor:'授权商展示',
    indexMore:'探索更多',
    indexPrice:'市场参考价',
    indexModel:'车型',
    common:'共',
    kind:'款',
    // 详情页面
    Reference:'参考价',
    wan:'万',
    // 技术参数
    lwh:'长宽高',
    curb:'整备质量',
    wheelbase:'轴距',
    power:'功率',
    displacement:'排量',
    torque:'扭矩',
    tank:'油箱',
    size:'轮胎尺寸',
    technical:'技术参数',
    configuration:'主要配置',
    // 底部导航
    index:'首页',
    garagePublicAccount:'车库公众号',
    garageNetworkTechnology:'车库网络科技',
    garageVibrato:'车库抖音',
    contactUs:'联系我们',
    copyright:'车库网络科技有限公司版权所有',
    // 关于我们
    firstBusiness:'第一业务板块-进口板块',
    firstContent:'历经近20年的奋战，在汽车进口领域已经位居行业前茅。国际采购涉及全球几十个国家，300多个车源供应商。其中主要中东、美国、加拿大、墨西哥、德国、俄罗斯、英国，采购车型累计超过40种，霸道系列、酷路泽系列、日产系列、三菱系列、雷克萨斯系列，中东版的采购总量占同行业65%以上。累计采购数额为¥ 134,475,000,000.00（截止2020年12月）',
    secondBussiness:'第二业务板块-出口板块',
    secondContentOne:'为打造全球出口项目，专门在迪拜成立了一家【远洋汽车贸易有限公司】，以逆平行进口车模式开展中国的汽车出口业务。同时开展全球各个国家之间的汽车贸易。公司总部设立在阿联酋迪拜自由贸易港，在中东多个国家设立了办事处，分别有阿曼、卡塔尔和科威特等办事处。同时在美国、俄罗斯、加拿大、日本、德国等重点车源地成立了合资公司或者办事处。',
    secondContentTwo:'公司在迪拜还拥有一家经过奔驰等主机厂授权的改装定制工厂，工厂占地10000多平米，建筑面积22000平米，年产能达到2000辆。',
    secondContentThree:'供应商体系覆盖68个国家，供应商数量超过300家，全球经销商数量超过1200家。',
    secondContentFour:'2018年全年出口数量17000台，2019年全年出口数量16700台，2020年上半年完成3695台车辆出口',
    secondContentFive:'随着全球客户对新能源汽车需求的增长，远洋贸易于2020年开始也致力于在新能源汽车业务中为客户提供最优质的服务。至今已完成新能源汽车出口车型为大众ID系列、日产纯电EV、长安ESTAR等车型，销售至非洲尼日利亚、阿尔及利亚、摩洛哥、加纳、塞舌尔等国家，以及中东等地区。新能源进口资质授权国家：沙特、伊朗、伊拉克、科威特、阿联酋、阿曼、卡塔尔、巴林、土耳其、以色列、巴勒斯坦、叙利亚、黎巴嫩、约旦、也门和塞浦路斯、埃及、利比亚、突尼斯、阿尔及利亚、摩洛哥、马德拉群岛、亚速尔群岛。',
    threeBussiness:'第三业务板块-品牌连锁板块',
    threeContentOne:'品牌销售渠道的矩阵布局，深耕品牌的环形营销战略。大丰田战略',
    threeContentTwo:'以丰田品牌为核心，针对一汽丰田、广汽丰田、以及丰田的高端品牌雷克萨斯，开展以并购、合资、申请等模式的连锁布局。新能源圈地战略以新能源中高端品牌、优质品牌为扩展核心。进行全国重点地区的销售渠道布局。重点发展区域，结合经济发展情况、国民购车需求等要素。设定了四大发展区域：重庆，广东，江苏，浙江。',
    fourBussiness:'第四业务板块-定制改装板块',
    fourContentOne:'打造品牌价值核心，建立品牌壁垒，开创品牌发展新动线。改装板块的三个改装类型：进口车区隔改装，主要针对第一板块的进口车进行改装。形成车库自有品牌造型。霸道、酷路泽、雷克萨斯等；',
    fourContentTwo:'进口车区隔改装，主要针对第一板块的进口车进行改装。形成车库自有品牌造型。霸道、酷路泽、雷克萨斯等；',
    fourContentThree:'高端定制改装，主要针对高端商务出行，以奔驰V系列、丰田塞纳等MPV为原型车进行品牌定制化升级改装；',
    fourContentFour:'国产车改装，与国内各大主机厂合作，将其部分车型进行提升、修正类改装。从而提升其销量。已经在岳阳投资建设改装基地。总占地105.12亩，总建筑规模8万多平米.设立汽车改装定制厂房、机动车检测认证中心、综合楼、一类改装厂房、二类改装厂房、仓库、活动中心、展示中心、餐厅等等。',
    // fiveBussiness:'第五业务板块-产业信息化设计与管理',
    // fiveContent:'通过对平行进口的研究和实际运营，车库网的产品研发团队陆续已经推出了“平行进口车库存系统”、“平行进口车ERP系统”、“企业管理OA系统”、“企业运营DMS管理系统”、“物流运输管理系统”、“售后车辆管理系统”。还将陆续推出“汽车经纪人销售平台”、“汽车展销及零配件的AI模式”等等。通过互联网的技术和平行进口车紧密结合，推出以“车库网”品牌为核心的一系列平行进口车互联网+解决方案。',
    // 资料下载
    video:'短视频',
    img:'图片',
    detail:'详情',
    allDownload:'全部下载',
    cancel:'取消',
    yes:'确定',
    proof:'身份验证',
    enterPhone:'请输入手机号',
    downloadTitle:'精品大图/视频,涵盖各种高清车系',
    // 销售渠道
    salephone:'全国零售业务电话',
    complaints:'投诉热线',
    borker:'金牌经纪人',
    job:'从业时长',
    saleNum:'成交数量',
    saleYear:'年',
    saleTower:'台',
    chat:'微信',
    saleTelephone:'全国经销商加盟电话',
    saleTitle:'车库品牌全国授权经销商',
    carToyota:'丰田（平行进口）',
    carLexus:'雷克萨斯（平行进口）',
    carALL:'综合（平行进口）',
    salePro:'区域',
    saleName:'经销商名称',
    saleAdress:'地址',
    saleContact:'联系人',
    telephone:'电话',
    modelAuthorize:'授权车型',

}
export default zh;
