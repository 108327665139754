<template>
  <div class="cheku">
    <router-view v-if="isShow"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow:true,
    }
  },
  provide(){
    return {
      reload:this.reload
    }
  },
  methods: {
    reload() {
      this.isShow=false;
      this.$nextTick(()=>{
        this.isShow=true
      })
    }
  },
};
</script>

<style lang="scss">
.cheku {
  width: 100%;
  overflow: hidden;
}
* {
  box-sizing: border-box;
  font-family: "PingFang", "SC-Semibold", "PingFang SC";
  margin: 0;
  padding: 0;
  ul,
  ol,
  li {
    list-style-type: none;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  a:hover {
    text-decoration: none;
  }
}
</style>
