// 创建文件ja.js
const ja = {
    language: {
        zh: '中文',
        en: 'English',
        ja:'Japanese'
    },
    // 头部导航
    globalization:'Japanese',
    modelCar:'モデルカー',
    saleChannel:'販売チャネル',
    download:'ダウンロード',
    aboutUs:'私たちに関しては',
    toyotaSeries:'トヨタ系',
    lexusSeries:'レクサスシリーズ',
    AllSeries:'総合シリーズ',
    // 首页
    indexToyota:'トヨタ',
    indexLexus:'レクサス部門',
    indexAll:'総合部門',
    showLicensor:'ライセンシーの表示',
    indexMore:'もっと調べる',
    indexPrice:'市場参考価格',
    indexModel:'車のモデル',
    common:'合計',
    kind:'支払い',
    // 详情页面
    Reference:'参考价',
    wan:'万',
// 技术参数
    lwh:'長さ 幅 高さ',
    curb:'車両重量',
    wheelbase:'ホイールベース',
    power:'パワー',
    displacement:'変位',
    torque:'トルク',
    tank:'タンク',
    size:'タイヤの大きさ',
    technical:'技術パラメータ',
    configuration:'主な構成',
    // 底部导航
    index:'トップページ',
    garagePublicAccount:'ガレージ公式アカウント',
    garageNetworkTechnology:'ガレージネットワークテクノロジー',
    garageVibrato:'ガレージビブラート',
    contactUs:'お問い合わせ',
    copyright:'Copyright ガレージネットワークテクノロジー株式会社',

    // 关于我们
    firstBusiness:'ファーストビジネスプレート-インポートプレート',
    firstContent:'ガレージ・ネットワーク・テクノロジー株式会社の国際調達事業は、世界20カ国以上、50社以上のサプライヤーを抱えている。ドバイ、カナダ、米国、ドイツに支社を置いている。年間調達台数は約3万台で、調達額は約20億ドル。調達車種は、日産、トヨタ、ランドローバー、ベンツ、 bmw、フォード、マセラティ、ポルシェ、シビックなど十数ブランドの40車種あまり。国内平行輸入車の国際調達台数は7年連続でトップ。',
    secondBussiness:'第2業務プレート-輸出プレート',
    secondContentOne:'ガレージ・ネットワーク・テクノロジー有限公司は天津、岳陽市、江陰市、南沙、南沙などの海港と内陸港にパイロット資質企業を累計8社保有している。各港で通関検査、倉庫輸送、卸売りの分配業務を行っている。',
    secondContentTwo:'ガレージカンパニーは、全国のディーラーに配送サービス、店舗への配送サービス、および商人のニーズに従って在庫金融や物流金融などの金融サービスを提供しています。',
    secondContentThree:'サプライヤー体制は 68 か国をカバーし、サプライヤー数は 300 を超え、グローバル ディストリビューターの数は 1,200 を超えます。',
    secondContentFour:'2018年の輸出台数は17,000台、2019年の輸出台数は16,700台で、2020年上半期には3,695台の輸出が完了した。',
    secondContentFive:'世界中の顧客からの新エネルギー車に対する需要の高まりに伴い、Sino-Ocean Trading は 2020 年以来、新エネルギー車ビジネスで最高品質のサービスを顧客に提供することにも取り組んできました。 これまでのところ、新エネルギー車の輸出モデルは、フォルクスワーゲン ID シリーズ、日産の純電気 EV、長安 ESTAR などのモデルで、アフリカ、ナイジェリア、アルジェリア、モロッコ、ガーナ、セイシェルなどの国々、および中東に販売されています。東と他の地域。 新エネルギー輸入資格国：サウジアラビア、イラン、イラク、クウェート、UAE、オマーン、カタール、バーレーン、トルコ、イスラエル、パレスチナ、シリア、レバノン、ヨルダン、イエメン、キプロス、エジプト、リビア、チュニジア、アルジェリア、モロッコ、マレーシアデルレー諸島、アゾレス諸島。',
    threeBussiness:'第三事業部門 - ブランドチェーン部門',
    threeContentOne:'ガレージネットワークテクノロジー株式会社は、全国30都道府県・地域に協力端末事業者約3600社、通年事業取引活性化事業者約1200社を保有しています。地級市以上の地域の加入率は70% に達する。年間販売台数は約2万7000台、年間売上高は約200億円。',
    threeContentTwo:'販売を規制し、顧客に良質なサービスを提供するために、当社はガレージブランドを基盤として全国の省都にブランドチェーンの直営店を建設する。並行輸入車、カスタマイズ改造、スマートアプリなどの車種やアクセサリー、ブランドやカルチャー、トレンドや意識を直営店に取り入れる。まったく新しい自動車販売スペースを作る',
    fourBussiness:'第四事業セグメント - カスタムモディファイセグメント',
    fourContentOne:'Garage Network Technology Co., Ltd. の国際調達ビジネスには、世界中の 20 を超える国と 50 を超えるサプライヤーが関与しています。 ドバイ、カナダ、米国、ドイツに支店があります。 年間の購入台数は3万台以上、購入金額は約20億ドル。 購入したモデルには、日産、トヨタ、ランドローバー、メルセデスベンツ、BMW、フォード、三菱、ポルシェ、マセラティなど、12 以上のブランドの 40 以上のモデルが含まれます。 国内並行輸入車の海外買取実績で7年連続1位を獲得',
    fourContentTwo:'輸入車は、主に最初のセグメントの輸入車のために、さまざまなセクションで改造されています。 ガレージ独自のブランドシェイプを形成。 ドミニアリング、クールルーズ、レクサスなど。',
    fourContentThree:'ブランドのカスタマイズのアップグレードと変更のためのプロトタイプ車両として、メルセデスベンツVシリーズ、トヨタシエナ、およびその他のMPVを使用した、主にハイエンドのビジネス旅行向けのハイエンドのカスタマイズされた変更。',
    fourContentFour:'国産車の改造については、国内大手OEMと提携し、一部車種のグレードアップや改造を行っています。 それによって売上を伸ばしています。 岳陽の修理基地の建設に投資した。 総面積は105.12エーカー、総建設規模は80,000平方メートルを超え、自動車改造カスタム工場、自動車試験認証センター、総合ビル、一級改造工場を設立しました。 、第二種改造工場、倉庫、活動センター、展示場、飲食店など。',
    // fiveBussiness:'第五事業部門 - 産業情報化設計・管理',
    // fiveContent:'Garage.comの製品R&Dチームは、並行輸入の研究と実際の運用を通じて、「並行輸入車在庫システム」、「並行輸入車ERPシステム」、「企業管理OAシステム」、「企業運営DMS管理システム」を次々と立ち上げてきました。 、「物流・輸送管理システム」、「アフターセールス車両管理システム」。 また、「Auto Broker Sales Platform」、「Auto Show Sales and Parts AI Model」などを立ち上げる。 インターネット技術と並行輸入車を密接に組み合わせ、「Garage Network」ブランドを核にインターネット＋並行輸入車向けの一連のソリューションを展開。',
    // 资料下载
    video:'ショートビデオ',
    img:'写真',
    detail:'详细',
    allDownload:'すべてダウンロードする',
    cancel:'キャンセル',
    yes:'もちろん',
    proof:'認証',
    enterPhone:'電話番号を入力してください',
    downloadTitle:'あらゆる種類の高解像度カー シリーズをカバーする高品質の大きな画像/ビデオ',
    // 销售渠道
    salephone:'全国小売業電話',
    complaints:'苦情ホットライン',
    borker:'Gold broker',
    job:'労働時間',
    saleNum:'取引回数',
    saleYear:'年',
    saleTower:'タワー',
    chat:'微信',
    saleTelephone:'全国ディーラー加盟電話',
    saleTitle:'ガレージブランドの全国正規ディーラー',
    carToyota:'トヨタ(並行輸入)',
    carLexus:'レクサス(並行輸入)',
    carALL:'包括(並行輸入)',
    salePro:'範囲',
    saleName:'販売業者名',
    saleAdress:'住所',
    saleContact:'コンタクト',
    telephone:'電話',
    modelAuthorize:'認定モデル',

}
export default ja;
