import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "home",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/Index.vue"),
    meta: {
      title: "车库网_买平行进口车_首选车库网_靠谱、专业、省心",
    },
  },
  // 4快跳转的车型
  {
    path: "/modelcar",
    name: "modelcar",
    component: () => import("../views/Modelcar.vue"),
    meta: {
      title: "车型车系-车库网络科技有限公司",
    },
  },
  // 车辆详情
  {
    path: "/cardetail",
    name: "cardetail",
    component: () => import("../views/CarDetail.vue"),
  },
  // 销售渠道
  {
    path: "/sales",
    name: "sales",
    component: () => import("../views/Sales.vue"),
    meta: {
      title: "销售渠道",
    },
  },
  // 资料下载
  {
    path: "/download",
    name: "download",
    component: () => import("../views/Download.vue"),
    meta: {
      title: "资料下载",
    },
  },
  {
    path: "/VideoDownload",
    name: "videoDownload",
    component: () => import("../views/VideoDownload.vue"),
  },
  {
    path: "/ImgDownload",
    name: "imgDownload",
    component: () => import("../views/ImgDownload.vue"),
  },
  {
    path: "/aboutme",
    name: "aboutme",
    component: () => import("../views/Aboutme.vue"),
  },
  {
    path: "/bannerdatail",
    name: "bannerdatail",
    component: () => import("../views/BannerDetail.vue"),
  },
  // 广告页
  {
    path: '/advertising',
    name: 'advertising',
    component: () => import('../views/advertising.vue'),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
